<template>
    <div ref="modalgbp" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header mb-1" style="background-image: url('https://cdn.pixabay.com/photo/2019/12/11/05/10/baby-4687466_960_720.jpg')">
                    <!-- <img class="img-tipo border bg-white br-5" width="110" :src="`https://supertiendascomunal.com/6774-home_default/alpina-leche-200entera.jpg`" alt="" /> -->
                    <img v-if="info.foto !== null" class="img-tipo border bg-white br-5 obj-cover" width="110" :src="info.foto" alt="" />
                    <div v-else class="img-tipo br-5 bg-whitesmoke d-middle-center" style="width:90px;height:90px;">
                        <img height="70" src="/img/no-imagen/mercado.svg" alt="" />
                    </div>
                    <div class="ml-auto my-auto d-middle-center bg-white rounded-circle" style="width:24px;height:24px;">
                        <i class="icon-cancel-circled f-24 cr-pointer text-gr-general" data-dismiss="modal" />
                    </div>
                </div>
                <div class="modal-body overflow-auto custom-scroll" style="max-height:70vh;">
                    <div class="row mb-3 mt-5 text-general f-17 f-600 justify-center text-center px-3 pt-5">
                        {{ info.nombre }}
                    </div>
                    <div class="row my-1 text-general f-14 f-600 justify-center text-center px-3">
                        {{ separadorNumero(info.valor) }}
                    </div>
                    <div class="row my-3 text-general f-14 justify-center text-center px-3">
                        {{ info.descripcion }}
                    </div>
                    <div class="row m-3 text-general f-14 justify-center text-center px-3">
                        <b class="pr-1">
                            Presentación:
                        </b> {{ info.presentacion }}
                    </div>
                    <div class="row m-3 text-general f-14 justify-center text-center px-3">
                        <b class="pr-1">
                            Unidad de medida:
                        </b> Unidad
                    </div>
                    <div class="row m-3 text-general f-14 justify-center text-center px-3">
                        <b class="pr-1">
                            Venta mínima:
                        </b> {{ info.cantidad_minima }} Uni.
                    </div>
                </div>
                <div class="row mx-0 justify-center pb-3">
                    <button
                    type="button"
                    class="btn mx-2 bg-whitesmoke text-general2 br-10"
                    @click="cancelar"
                    v-text="textosBotones.cancelar"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {
                    nombre: 'Malteada de vainilla Francesa con palitos de chocolate',
                    foto_mini: 'https://img.culturacolectiva.com/content_image/2020/1/21/1579624512515-receta-de-frappe-de-chocolate-con-vino-tinto-en-5-minutos.003.jpeg',
                    presentacion:  '600',
                    sigla: 'ml',
                    valor: 14000,
                    cant_vendido: 51,
                    id_promocion: null,
                    tipo: 1,
                    color: 0,
                    borde: true,
                    posicion: 0,
                    texto: 'MÁS BARATO',
                    descuento: 0,
                    promo_descuento: null,
                    promo_valor: null,
                    id_promo_tienda: null,
                    agregado: false,
                    otra_promo: false,
                    has_carrito: false,
                    descripcion: '',
                    cantidad_minima: 0
                }
            }
        },

    },
    data(){
        return {
            // info: {
            //     nombre: '',
            //     presentacion: '',
            // },

            textosBotones: {
                listo: 'Listo',
                aceptar: 'Aceptar',
                guardar: 'Guardar',
                eliminar: 'Eliminar',
                cancelar: 'Cancelar'
            }
        }
    },
    methods: {
        toggle(){
            $(this.$refs.modalgbp).modal('toggle')
        },
        cancelar(){
            this.$emit('cancelar')
            this.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/sass/gbp.scss';
.modal-content{
    border: none;
    border-radius: 8px;

    .modal-header{
        height: 70px;
        // background:linear-gradient( rgba(50,163,253,.65) 1% , rgba(255,255,255,1) , rgba(255,255,255,1), rgba(255,255,255,1) );
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: none;
        background-size: cover;

        .close{
            outline: none;
            transition-duration: .85s;
            position: absolute;
            right: 10px;
            top: 1px;
            font-size: 2.5em;

            &:hover{
                transition-duration: .4s;
                transform: rotate(360deg) scale(1.2);
            }
        }
        .logo{
            display: block;
            // height: 70px;
            // width: 70px;

            span{
                font-size: 1.2em;
            }
        }
    }
    .modal-title{
        color: $azul2-primary;
        font-weight: normal;
    }
}
.img-tipo{
    position: absolute;
    top:20px;
    left: 50%;
    transform: translate(-50%);
}
</style>
